import React from "react";

function AdvancedCalculations() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="109"
            fill="none"
            viewBox="0 0 109 109"
        >
            <circle cx="54.5" cy="54.5" r="54.5" fill="#DFF0E7"></circle>
            <path
                fill="#000"
                d="M61.807 64.758c-1.898 0-3.002-2.211-2.933-5.043 0-2.834 1.035-5.079 2.899-5.079h.034c2.105 0 2.9 2.28 2.9 5.01 0 2.97-.898 5.112-2.9 5.112zm.108-11.954h-.034c-3.002 0-5.419 2.522-5.419 7.014.034 4.49 2.416 6.771 5.246 6.771 2.899 0 5.35-2.35 5.35-7.082 0-4.284-2.037-6.703-5.143-6.703zM44.245 50.42c0-2.833 1.035-5.078 2.899-5.078h.034c2.106 0 2.9 2.28 2.9 5.009 0 2.972-.898 5.114-2.9 5.114-1.898 0-3.002-2.212-2.933-5.043v-.002zm8.18-.207c0-4.284-2.037-6.703-5.143-6.703h-.035c-3.002 0-5.418 2.523-5.418 7.014.035 4.491 2.416 6.771 5.246 6.771 2.899 0 5.35-2.349 5.35-7.082zM60.047 43.476L46.83 66.762h1.933L61.98 43.476h-1.933z"
            ></path>
            <path
                fill="#000"
                d="M55.031 85h-.062a29.954 29.954 0 01-20.555-8.17A30.01 30.01 0 0125 55v-.418a30.01 30.01 0 018.61-20.632 29.955 29.955 0 0120.478-8.938V25h.943a29.954 29.954 0 0120.555 8.17A30.01 30.01 0 0185 55v.418a30.01 30.01 0 01-8.612 20.633 29.955 29.955 0 01-20.478 8.936V85h-.882l.003.001zm-.397-1.888h.397a28.068 28.068 0 0019.72-8.096 28.12 28.12 0 008.36-19.625v-.125-.27a28.121 28.121 0 00-8.73-20.367 28.07 28.07 0 00-19.096-7.74h-.316c-7.38 0-14.463 2.909-19.718 8.096a28.12 28.12 0 00-8.358 19.623v.395a28.122 28.122 0 008.7 20.338 28.071 28.071 0 0019.04 7.769v.002z"
            ></path>
        </svg>
    );
}

export default AdvancedCalculations;
