import React from "react";

function BoostEmployee() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="109"
            fill="none"
            viewBox="0 0 109 109"
        >
            <circle cx="54.5" cy="54.5" r="54.5" fill="#DFF0E7"></circle>
            <path
                fill="#000"
                d="M72.749 64.73h-5.57a.654.654 0 01-.653-.65V45.836h-1.055a.655.655 0 01-.606-.403.648.648 0 01.147-.71L69.51 40.3a.654.654 0 01.918 0l4.49 4.424a.649.649 0 01-.46 1.114h-1.055v18.24a.649.649 0 01-.653.65zm-4.918-1.3h4.265V45.187a.649.649 0 01.653-.65h.117l-2.904-2.86-2.904 2.86h.12a.654.654 0 01.653.65V63.43zM62.07 64.73H56.5a.654.654 0 01-.653-.65V48.73h-1.055a.655.655 0 01-.606-.402.648.648 0 01.148-.711l4.492-4.423a.654.654 0 01.917 0l4.493 4.423a.649.649 0 01-.459 1.114h-1.055V64.08a.649.649 0 01-.653.65zm-4.917-1.3h4.264V48.08a.649.649 0 01.652-.65h.12l-2.904-2.86-2.905 2.86h.12a.654.654 0 01.653.65V63.43zM51.391 64.73h-5.57a.654.654 0 01-.653-.65V51.625h-1.055a.655.655 0 01-.605-.404.647.647 0 01.147-.71l4.491-4.423a.654.654 0 01.919 0l4.492 4.423a.649.649 0 01-.46 1.114h-1.053V64.08a.648.648 0 01-.653.65zm-4.918-1.3h4.266V50.975a.649.649 0 01.652-.65h.119l-2.904-2.86-2.905 2.86h.12a.654.654 0 01.652.65V63.43zM41.179 64.73h-5.571a.654.654 0 01-.653-.65v-9.74h-1.057a.654.654 0 01-.606-.403.647.647 0 01.148-.71l4.491-4.424a.654.654 0 01.917 0l4.494 4.423a.648.648 0 01-.459 1.114h-1.052v9.74a.648.648 0 01-.652.65zm-4.919-1.3h4.266v-9.74a.648.648 0 01.653-.65h.118l-2.905-2.859-2.904 2.86h.12a.654.654 0 01.652.65v9.739z"
            ></path>
            <path
                fill="#000"
                d="M88.169 49.693a.8.8 0 00-1.125 0l-2.601 2.59a29.95 29.95 0 00-9.393-20.095A30.194 30.194 0 0054.367 24h-.331a30.208 30.208 0 00-18.789 6.815A29.988 29.988 0 0024.88 47.842l1.584.171a28.237 28.237 0 0110.693-16.668 28.45 28.45 0 0119.049-5.598 28.418 28.418 0 0118.067 8.215A28.196 28.196 0 0182.5 51.964l-2.28-2.271a.797.797 0 00-1.358.56c0 .21.084.412.234.56l3.216 3.204.259.258.499.496a.8.8 0 001.08.042l.045-.042.5-.493 3.474-3.46a.795.795 0 000-1.125zM74.216 74.096a28.404 28.404 0 01-14.044 7.597 28.48 28.48 0 01-15.94-1.169 28.357 28.357 0 01-12.774-9.564 28.18 28.18 0 01-5.556-14.924l2.281 2.271a.796.796 0 001.298-.863.793.793 0 00-.172-.257l-3.477-3.466-.497-.496a.8.8 0 00-1.08-.041c-.016.014-.032.027-.046.041l-3.975 3.958a.792.792 0 00.562 1.356.799.799 0 00.564-.232l2.6-2.59a29.95 29.95 0 009.393 20.094A30.194 30.194 0 0054.034 84h.331a30.207 30.207 0 0018.697-6.738 29.986 29.986 0 0010.41-16.87l-1.579-.197a28.333 28.333 0 01-7.677 13.901z"
            ></path>
        </svg>
    );
}

export default BoostEmployee;
