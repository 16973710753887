import React from "react";

function QuickResults() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="109"
            fill="none"
            viewBox="0 0 109 109"
        >
            <circle cx="54.5" cy="54.5" r="54.5" fill="#DFF0E7"></circle>
            <path
                fill="#000"
                d="M55.031 85h-.062a29.954 29.954 0 01-20.555-8.17A30.01 30.01 0 0125 55v-.418a30.01 30.01 0 018.61-20.632 29.955 29.955 0 0120.478-8.938V25h.943a29.954 29.954 0 0120.555 8.17 30.01 30.01 0 019.36 20.055c.036.61.054 1.207.054 1.775v.418a30.01 30.01 0 01-8.612 20.633 29.955 29.955 0 01-20.478 8.936V85h-.882l.003.001zm-.397-1.888h.397a28.068 28.068 0 0019.72-8.096 28.12 28.12 0 008.36-19.625v-.125-.27a28.121 28.121 0 00-8.73-20.367 28.07 28.07 0 00-19.096-7.74h-.316c-7.38 0-14.463 2.909-19.718 8.096a28.12 28.12 0 00-8.358 19.623v.395a28.122 28.122 0 008.7 20.338 28.071 28.071 0 0019.04 7.769v.002z"
            ></path>
            <path
                fill="#000"
                d="M38.71 60.578a2.006 2.006 0 01-1.85-1.23 1.984 1.984 0 01.434-2.17l10.751-10.7a1.987 1.987 0 011.415-.58c.53 0 1.038.209 1.415.58l5.632 5.608 9.224-9.198h-1a2.006 2.006 0 01-1.375-.605 1.99 1.99 0 010-2.776 2.006 2.006 0 011.375-.604h5.865c.517.009 1.01.217 1.377.58.367.364.578.854.59 1.369v5.85a1.99 1.99 0 01-.608 1.37 2.006 2.006 0 01-2.787 0 1.99 1.99 0 01-.607-1.37v-.996L57.914 56.307a2.02 2.02 0 01-2.83 0l-5.632-5.606-9.328 9.289a1.99 1.99 0 01-1.415.588zM49.451 46.86a1.032 1.032 0 00-.728.3L37.979 57.86a1.025 1.025 0 00.73 1.754 1.035 1.035 0 00.73-.3l10.014-9.973 6.316 6.286a1.03 1.03 0 001.125.223 1.03 1.03 0 00.334-.223l12.302-12.25v3.326a1.027 1.027 0 001.032.984 1.035 1.035 0 001.032-.984v-5.798a1.026 1.026 0 00-.637-.952 1.037 1.037 0 00-.396-.079h-5.83a1.035 1.035 0 00-.988 1.028 1.027 1.027 0 00.988 1.028h3.34L56.498 53.45l-6.322-6.29a1.037 1.037 0 00-.724-.301zM38.71 69.642c-.531 0-1.04-.211-1.416-.585a1.991 1.991 0 01-.587-1.41v-2.769a1.99 1.99 0 01.608-1.37 2.006 2.006 0 012.787 0c.373.361.591.853.608 1.37v2.77a1.99 1.99 0 01-.586 1.409 2.006 2.006 0 01-1.415.585zm0-5.79a1.035 1.035 0 00-1.034 1.026v2.77a1.026 1.026 0 001.032 1.073 1.037 1.037 0 00.967-.666c.05-.13.072-.268.066-.407v-2.77a1.027 1.027 0 00-1.032-1.026zM44.018 69.642c-.53 0-1.04-.211-1.415-.585a1.992 1.992 0 01-.587-1.41v-5.79a1.99 1.99 0 01.608-1.369 2.006 2.006 0 012.786 0c.374.36.592.852.608 1.37v5.79a1.99 1.99 0 01-.586 1.409 2.007 2.007 0 01-1.414.585zm0-8.81a1.035 1.035 0 00-1.033 1.026v5.79a1.026 1.026 0 00.626.99 1.037 1.037 0 001.373-.583c.049-.13.071-.268.065-.407v-5.79a1.026 1.026 0 00-.305-.722 1.035 1.035 0 00-.726-.3v-.005z"
            ></path>
            <path
                fill="#000"
                d="M49.327 69.642c-.531 0-1.04-.211-1.416-.585a1.992 1.992 0 01-.587-1.41v-10.32a1.99 1.99 0 01.608-1.37 2.006 2.006 0 012.787 0c.373.36.591.852.608 1.37v10.32a1.99 1.99 0 01-.586 1.41 2.006 2.006 0 01-1.414.585zm0-13.342a1.035 1.035 0 00-1.034 1.026v10.322a1.025 1.025 0 00.627.99 1.035 1.035 0 001.438-.99V57.326a1.025 1.025 0 00-1.031-1.026zM54.635 69.642c-.53 0-1.04-.211-1.415-.585a1.991 1.991 0 01-.587-1.41v-6.544c0-.529.211-1.036.586-1.41a2.006 2.006 0 012.832 0c.375.374.586.882.586 1.41v6.545c0 .529-.211 1.035-.587 1.41a2.008 2.008 0 01-1.415.584zm0-9.567a1.035 1.035 0 00-1.033 1.028v6.545a1.035 1.035 0 002.066 0v-6.545c0-.273-.109-.534-.302-.727a1.035 1.035 0 00-.73-.301zM59.944 69.642c-.531 0-1.04-.211-1.415-.585a1.99 1.99 0 01-.586-1.41V60.35a1.988 1.988 0 011.221-1.898 2.01 2.01 0 012.218.448 1.993 1.993 0 01.564 1.45v7.299c0 .529-.212 1.035-.587 1.41a2.008 2.008 0 01-1.415.584zm0-10.322a1.035 1.035 0 00-1.031 1.029v7.299a1.026 1.026 0 00.626.99 1.037 1.037 0 001.372-.583c.05-.13.072-.268.066-.407v-7.3a1.027 1.027 0 00-1.033-1.028zM65.252 69.641c-.53 0-1.04-.21-1.414-.584a1.99 1.99 0 01-.586-1.41V55.82a1.988 1.988 0 011.22-1.898 2.008 2.008 0 012.648 1.116c.097.249.143.515.134.782v11.83a1.991 1.991 0 01-.587 1.409 2.008 2.008 0 01-1.415.583zm0-14.86a1.035 1.035 0 00-1.03 1.028V67.64a1.026 1.026 0 00.626.99 1.037 1.037 0 001.372-.583c.05-.13.071-.268.065-.407V55.819a1.024 1.024 0 00-.637-.952 1.038 1.038 0 00-.396-.079v-.008zM70.56 69.642c-.53 0-1.039-.211-1.414-.585a1.99 1.99 0 01-.585-1.41V51.286a1.986 1.986 0 01.563-1.45 2 2 0 011.438-.605 2.01 2.01 0 011.437.606 1.993 1.993 0 01.564 1.45v16.362c0 .529-.212 1.035-.587 1.41a2.008 2.008 0 01-1.415.584zm0-19.385c-.273 0-.535.109-.728.302a1.026 1.026 0 00-.302.726v16.363a1.024 1.024 0 00.626.99 1.037 1.037 0 001.372-.583c.05-.13.072-.268.066-.407V51.285a1.027 1.027 0 00-1.033-1.028z"
            ></path>
        </svg>
    );
}

export default QuickResults;
