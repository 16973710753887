import React, { ReactElement } from "react";
import colors from "@styles/variables/payrollsystemVariables.module.scss";

import FeatureList from "@components/shared/featureList";
import SaveTime from "../icons/saveTime";
import CostEffective from "../icons/costEffective";
import BoostEmployee from "../icons/boostEmployee";
import AdvancedCalculations from "../icons/advancedCalculations";
import CustomizableDesign from "../icons/customizableDesign";
import QuickResults from "../icons/quickResults";

const whyUsList = [
    {
        title: "Save Time",
        description:
            "Time is of great value to businesses. With payroll software solutions, you don't have to worry about manually entering long pay stubs and payroll calculations.",
        icon: <SaveTime />,
    },
    {
        title: "Cost Effective",
        description:
            "Payroll platforms can help you save large amounts of money spent on manual payroll processing. Your business can cut costs with professional services that can help you control payroll and increase your returns.",
        icon: <CostEffective />,
    },
    {
        title: "Boost Employee Productivity",
        description:
            "A traditional payroll management system could delay the payment. But you can count on the payroll software to satisfy the employee expectation at the right time.",
        icon: <BoostEmployee />,
    },
    {
        title: "Advanced Calculations",
        description:
            "Traditional methods of adding up numbers on your fingers or using an outdated calculator are no longer relevant.",
        icon: <AdvancedCalculations />,
    },
    {
        title: "Customizable Design",
        description:
            "You can truly own the software platform and personalize applications to suit your business needs.",
        icon: <CustomizableDesign />,
    },
    {
        title: "Quick Results",
        description:
            "Thanks to the software's fast-paced working, Using payroll software doubles the operating speed and gives you access to instant results.",
        icon: <QuickResults />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Benefits of Payroll Systems"
            classes={{
                mainTitleClasses:
                    "text-center text-2xl lg:text-4xl mb-14 font-bold",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: " children:mx-auto",
                titleClasses: "font-semibold text-lg lg:text-xl mt-6 ",
                descriptionClasses:
                    "font-light text-sm lg:text-base text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                descriptionColor: colors.whyUsBriefColor,
            }}
        />
    );
}
